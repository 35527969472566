<template>
  <div>
    <RightSideBar
      v-if="isOpen"
      @close="isOpen = false"
      submit="Next"
      width="520px !important"
      button-class="bg-dynamicBackBtn text-white"
      @submit="$refs.delegation.toggle({delegationReason: delegationReason})"
      :disabled-button="(!Object.keys(delegator).length || !Object.keys(delegatee).length)"
    >
      <template v-slot:title>
        <p class="text-darkPurple font-bold text-lg leading-5 p-0 -ml-2">
          New Delagation
        </p>
      </template>
      <template v-slot:default>
        <template>
          <div class="w-full flex flex-col justify-start gap-5">
            <template>
              <div class="w-full flex flex-col justify-start items-start gap-2">
                <p class="font-semibold text-base text-darkPurple">
                  Reason for Delegation
                </p>
                <c-radio
                  v-model="delegationReason"
                  class="_delegation-radio"
                  space-between="mr-2 ml-2"
                  :rules="['required']"
                  :options="[
                    { name: 'Vacant Position', value: 'vacant' },
                    { name: 'Unavailable Employee', value: 'unavailable'},
                    { name: 'Support', value: 'support'},
                  ]"
                />
              </div>
            </template>
            <template>
              <div class="w-full flex flex-col justify-start items-start gap-5">
                <template v-if="delegationReason === 'vacant'">
                  <div class="w-full flex flex-col justify-start items-start gap-2">
                    <p class="font-semibold text-base text-darkPurple">Delegator</p>
                    <SearchDesignations @selected="onSelected($event, 1)" />
                  </div>
                </template>
                <template v-if="delegationReason === 'unavailable'">
                  <div class="w-full flex flex-col justify-start items-start gap-2">
                    <p class="font-semibold text-base text-darkPurple">Delegator</p>
                    <SearchEmployees @selected="onSelected($event, 2)" />
                  </div>
                </template>
                <template v-if="delegationReason === 'support'">
                  <div class="w-full flex flex-col justify-start items-start gap-2">
                    <p class="font-semibold text-base text-darkPurple">Delegator</p>
                    <SearchEmployees @selected="onSelected($event, 2)" />
                  </div>
                </template>
                <template>
                  <div class="w-full flex flex-col justify-start items-start gap-2">
                    <p class="font-semibold text-base text-darkPurple">Delegatee</p>
                    <SearchEmployees @selected="onSelected($event, 3)" />
                  </div>
                </template>
              </div>
            </template>
          </div>
        </template>
      </template>
    </RightSideBar>
    <template>
      <Delegation
        ref="delegation"
        :delegator="delegator"
        :delegatee="delegatee"
        :delegation-reason="delegationReason"
        @success="isOpen = false, $emit('success', $event)"
      />
    </template>
  </div>
</template>

<script>
import * as _ from "lodash"
import CRadio from "@scelloo/cloudenly-ui/src/components/radio"
import RightSideBar from "@/components/RightSideBar"

export default {
  components: {
    CRadio,
    RightSideBar,
    Delegation: () => import("./delegation"),
    SearchEmployees: () => import("./searchEmployees"),
    SearchDesignations: () => import("./searchDesignations"),
  },
  data(){
    return {
      isOpen: false,
      delegator: {},
      delegatee: {},
      supportType: '',
      delegationReason: 'vacant',
    }
  },
  methods: {
    toggle(){
      this.delegator = {}
      this.delegatee = {}
      this.isOpen = !this.isOpen
    },
    onSelected(payload, type){
      const $fn = (fn) => (_.trim(fn) || '-')
      const {userReportingTo} = payload
      if(_.isEqual(type, 1)){
        this.delegator = {
          ...payload,
          fname: $fn(payload.name),
          orgFunction: $fn(payload.orgFunction?.name),
          reportingTo: $fn(payload.reportsTo?.name),
          level: $fn(payload.level?.name),
          designation: $fn(payload?.name),
        }
      }
      if(_.isEqual(type, 2)){
        this.delegator = {
          ...payload,
          userId: payload.userId,
          fname: $fn(payload.fname),
          lname: $fn(payload.lname),
          officeAddress: $fn(payload.employeeOffice?.name),
          orgFunction: $fn(payload.orgFunction?.name),
          level: $fn(payload.userLevel?.name),
          designation: $fn(payload.userDesignation?.name),
          reportingTo: $fn(`${userReportingTo?.fname || ''} ${userReportingTo?.lname || ''}`)
        }
      }
      if(_.isEqual(type, 3)){
        this.delegatee = {
          ...payload,
          userId: payload.userId,
          fname: $fn(payload.fname),
          lname: $fn(payload.lname),
          officeAddress: $fn(payload.employeeOffice?.name),
          orgFunction: $fn(payload.orgFunction?.name),
          level: $fn(payload.userLevel?.name),
          designation: $fn(payload.userDesignation?.name),
          reportingTo: $fn(`${userReportingTo?.fname || ''} ${userReportingTo?.lname || ''}`)
        }
      }
    },
  }
}
</script>

<style scoped>
._delegation-radio {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  font-size: 16px !important;
  color: #333333 !important;
}
._text-10 {
  font: 10px !important;
}
</style>
<style>
  .rightSidebar {
    width: 520px !important;
  }
</style>
